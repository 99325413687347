
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ActualPlant } from '@/types/plantEstimation'

@Component({
  name: 'PlantEstimationActual'
})
export default class PlantEstimationActual extends Vue {
  @Prop() private id!: string
  private loading = false
  private tableData: ActualPlant[] = []

  created () {
    if (this.id) {
      this.getDetail()
    }
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.plantEstimated.selectPlantMessByList,
      {
        estimatedId: this.id
      }).then((res) => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onChange (value: string, row: ActualPlant, index: number) {
    if (!/^(0|[1-9][0-9]*)$/.test(value)) {
      row.actualCount = '0'
      this.tableData[index].actualCount = '0'
    }
  }

  // 关闭弹框
  closeDialog () {
    this.$emit('updateInfo')
  }

  onSubmit () {
    const data: Array<{estimatedId: string; messId: string; actualCount: string}> = []
    this.tableData.forEach((item) => {
      data.push({
        estimatedId: item.estimatedId,
        messId: item.messId,
        actualCount: item.actualCount
      })
    })
    this.$axios.post(this.$apis.plantEstimated.updatePlantMessList, data)
      .then(() => {
        this.$message.success('保存成功')
        this.closeDialog()
      })
  }
}
